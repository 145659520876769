import { Create } from 'react-admin'
import Form from './form'
import { withTicketImage } from '../../hoc'

const FormWithTicketImage = withTicketImage(Form)

const CreateView = (props) => {
  const { record } = props
  const imageUrl = record.ticket_image_url
  const senderPhone = record?.sender?.phone

  return (
    <FormWithTicketImage
      {...props}
      imageUrl={imageUrl}
      senderPhone={senderPhone}
    />
  )
}

const TonnageTicketsCreate = props => {
  return (
    <Create {...props} >
      <CreateView useOptimisticSave />
    </Create>
  )
}

export default TonnageTicketsCreate
