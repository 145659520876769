import fieldNames from './field-names'
import pick from 'lodash/pick'

const formDefaultDriver = {
  defaultDriver: 'Default driver for selected truck: \'%{name}\'',
  noDefaultDriver: 'No default driver for selected truck',
}

const aggregateRevenues = {
  name: 'customer',
  revenue_in_dollars: 'revenue',
  tickets_count: 'tickets',
  date: 'date',
}

const extractionMappings = {
  text: 'extracted text',
  ticket_vendor: 'ticket vendor',
}

const materialsFieldNames = pick(fieldNames, [
  'name',
])

const companiesFieldNames = {
  ...pick(fieldNames, [
    'name',
    'address',
    'ledger_code',
    'email',
    'phone_number'
  ]),
  is_exempt_from_sales_tax: 'Exempt from sales tax?',
  tonnage_invoice_grouping: 'Tonnage invoice grouping',
}

const sitesFieldNames = pick(fieldNames, [
  'name',
  'company',
  'address',
  'zone',
])

const trucksFieldNames = pick(fieldNames, [
  'number',
  'default_driver',
  'is_active',
])

const tonnageTicketsFieldNames = {
  ...pick(fieldNames, [
    'customer',
    'recipient',
    'driver',
    'material',
    'dropoff_site',
    'pickup_site',
    'truck',
    'fuel_surcharge_in_dollars',
    'material_surcharge_in_dollars',
    'base_rate_in_dollars',
    'ticket_number',
    'worked_at_date',
    'net_weight_in_tons',
    'equipment_rate_in_dollars',
    'material_sale_price_in_dollars',
  ]),
  tonnage_rate: 'tonnage rate',
  snapshot_mode: 'archived?',
  is_completable: 'complete',
  tonnage_ticket_invoice_period_end_date: 'invoice week ending',
  is_flat_haul_rates: 'flat haul rates?',
  is_flat_material_sale_price: 'flat material sale price?',
}

const tonnageTicketsForm = {
  labels: {
    tonnageRate: 'Find tonnage rate by code',
  },
  general: {
    ...formDefaultDriver,
    currentRateValue: 'Suggested from current tonnage rate: \'%{value}\'',
    autofilledValueFromRate: 'Autofilled from tonnage rate: \'%{value}\'',
    autofilledBlankValueFromRate: 'No value from current tonnage rate',
    noRate: 'No tonnage rate found yet',
    selectedRateWithCode: 'Tonnage rate: %{code}',
    selectedRateWithoutCode: 'Found rate',
    inactiveRate: 'inactive',
  },
  info: {
    formHelper: 'Find tonnage rate by code or by selecting customer, recipient, pickup site, dropoff site and material. Finding a tonnage rate by code will autofill customer, recipient, pickup site, dropoff site and material.',
  },
  validate: {
    tonnageTooHigh: 'Can not exceed %{max} tons',
  },
}

const hourlyTicketsFieldNames = {
  ...pick(fieldNames, [
    'customer',
    'truck',
    'driver',
    'ticket_number',
    'regular_rate_in_dollars',
    'overtime_rate_in_dollars',
    'doubletime_rate_in_dollars',
    'description',
  ]),
  hourly_rate: 'hourly rate',
  regular_time_in_hours: 'regular hours',
  overtime_in_hours: 'overtime hours',
  doubletime_in_hours: 'doubletime hours',
  regular_travel_time_in_hours: 'regular travel time hours',
  overtime_travel_time_in_hours: 'overtime travel time hours',
  doubletime_travel_time_in_hours: 'doubletime travel time hours',
  worked_at_date: 'date',
  payscale: 'payscale',
  snapshot_mode: 'archived?',
  hourly_ticket_invoice_period_end_date: 'invoice week ending',
  fees_in_dollars: 'disposal charges',
}

const hourlyTicketsForm = {
  general: {
    ...formDefaultDriver,
    currentRateValue: 'Suggested from current hourly rate: \'%{value}\'',
    autofilledValueFromRate: 'Autofilled from hourly rate: \'%{value}\'',
    noRate: 'No hourly rate found yet',
    selectedRate: 'Hourly rate: %{code} \'%{description}\'',
  },
  labels: {
    hourlyRate: 'Find hourly rate by code',
  },
  info: {
    formHelper: 'Find hourly rate by code.',
  },
}

const tonnageRatesFieldNames = {
  ...pick(fieldNames, [
    'description',
    'customer',
    'recipient',
    'material',
    'pickup_site',
    'dropoff_site',
    'base_rate_in_dollars',
    'fuel_surcharge_in_dollars',
    'material_surcharge_in_dollars',
    'code',
    'is_active',
    'equipment_rate_in_dollars',
    'material_sale_price_in_dollars',
  ]),
  start_date: 'effective from',
  end_date: 'effective thru',
  is_flat_rate: 'flat rate?',
}

const resources = {
  // conceptual combination tonnage + hourly
  tickets: {
    fieldNames: {
      ...pick(fieldNames, [
        'ticket_number',
      ]),
    },
  },
  tonnage_rates: {
    name: 'tonnage rate |||| tonnage rates',
    fieldNames: tonnageRatesFieldNames,
    form: {
      general: {
        noZone: 'No zone assigned',
        materialSalePrice: {
          currentRateValue: 'Suggested from pickup & material: \'%{value}\'',
          autofilledValueFromRate: 'Autofilled from pickup & material: \'%{value}\'',
          noRate: 'No price found',
        },
        zoneRate: {
          currentRateValue: 'Suggested from pickup & dropoff zones: \'%{value}\'',
          autofilledValueFromRate: 'Autofilled from pickup & dropoff zones: \'%{value}\'',
          noRate: 'No rate found',
        },
      },
    },
  },
  mandated_wage_coefficients: {
    name: 'wage coefficient |||| wage coefficients',
    fieldNames: {
      ...pick(fieldNames, [
        'rate',
      ]),
      amt_in_hours: 'amount',
    },
  },
  tonnage_tickets: {
    name: 'tonnage ticket |||| tonnage tickets',
    contextualType: 'tonnage',
    fieldNames: tonnageTicketsFieldNames,
    form: tonnageTicketsForm,
  },
  sites: {
    name: 'site |||| sites',
    fieldNames: sitesFieldNames,
  },
  materials: {
    name: 'material |||| materials',
    fieldNames: materialsFieldNames,
  },
  companies: {
    name: 'company |||| companies',
    fieldNames: companiesFieldNames,
    tonnage_invoice_grouping_type: {
      none: 'consolidate',
      dropoff_site: 'dropoff site',
      rate: 'rate',
    },
  },
  employee_drivers: {
    name: 'driver |||| drivers',
    fieldNames: {
      ...pick(fieldNames, [
        'first_name',
        'last_name',
        'percent_of_base_rate',
        'address',
        'is_active',
      ]),
      ssn: 'SSN',
      w4_submission_year: 'w-4 submission year',
      w4_filing_status: 'w-4 filing status',
      external_non_job_taxable_wages_per_year_in_dollars: 'External taxable wages per year ($)',
      tax_credits_in_dollars: 'Tax credits per year ($)',
      personally_claimed_deductions_in_dollars: 'Claimed deductions per year ($)',
      w4_step_two_checkbox_is_checked: 'w-4 step 2 checked?',
      number_of_personal_state_exemptions: '# Personal state exemptions',
      number_of_dependent_state_exemptions: '# Dependent state exemptions',
      number_of_federal_allowances: '# of federal allowances (pre 2020)',
      additional_state_withholding_in_dollars: 'Add\'l state withholdings per pay period ($)',
      additional_fed_withholding_in_dollars: 'Add\'l federal withholdings per pay period ($)',
      additional_county_withholding_in_dollars: 'Add\'l county withholdings per pay period ($)',
      hired_at_date: 'Date of hire',
    },
  },
  broker_drivers: {
    name: 'broker |||| brokers',
    fieldNames: {
      ...pick(fieldNames, [
        'name',
        'ledger_code',
        'percent_of_base_rate',
        'address',
        'hourly_work_deduction_in_dollars',
        'is_active',
      ]),
      hourly_regular_rate_in_dollars: 'hourly reg rate',
      hourly_overtime_rate_in_dollars: 'hourly OT rate',
      hourly_doubletime_rate_in_dollars: 'hourly DT rate',
    },
    form: {
      hourly_work_deduction: 'Rate deduction',
      hourly_rate: 'Rate',
    },
  },
  trucks: {
    name: 'truck |||| trucks',
    fieldNames: trucksFieldNames,
  },
  payscales: {
    name: 'payscale |||| payscales',
    fieldNames: {
      ...pick(fieldNames, [
        'is_certified',
        'regular_pay_rate_in_dollars',
        'regular_travel_time_pay_rate_in_dollars',
        'overtime_pay_rate_in_dollars',
        'doubletime_pay_rate_in_dollars',
      ]),
      code: 'code',
      type_of_payscale: 'type',
    },
  },
  hourly_rates: {
    name: 'hourly rate |||| hourly rates',
    fieldNames: {
      ...pick(fieldNames, [
        'code',
        'description',
        'customer',
        'regular_rate_in_dollars',
        'overtime_rate_in_dollars',
        'doubletime_rate_in_dollars',
        'is_certified',
        'payscale',
      ]),
      certified_location: 'certified location',
      certified_project_name: 'certified project name',
      work_class: 'work class'
    },
  },
  hourly_tickets: {
    name: 'hourly ticket |||| hourly tickets',
    contextualType: 'hourly',
    fieldNames: hourlyTicketsFieldNames,
    form: hourlyTicketsForm,
  },
  pending_hourly_tickets: {
    name: 'pending hourly ticket |||| pending hourly tickets',
    contextualType: 'hourly',
    fieldNames: hourlyTicketsFieldNames,
    form: {
      ...hourlyTicketsForm,
      edit: {
        saveButtonLabel: 'complete hourly ticket',
      },
    },
  },
  hourly_ticket_invoices: {
    name: 'hourly invoice |||| hourly invoices',
    fieldNames: {
      ...pick(fieldNames, [
        'customer',
        'total_in_dollars',
        'is_archived',
      ]),
      hourly_rate: 'rate',
      period_end_date: 'date',
      invoice_number: 'invoice #',
      tickets_count: 'tickets',
    },
    labels: {
      invoice: {
        workType: 'Hourly work',
        total_in_dollars: 'Total due',
        tickets_count: 'Total tickets',
      },
    },
    archiveAll: {
      action: 'archive all',
      confirmContent: 'Archive all hourly ticket invoices?',
      confirmTitle: 'Archive hourly ticket invoices',
      success: 'Successfully archived hourly ticket invoices',
      error: 'Error archiving hourly ticket invoices',
    },
  },
  invoice_hourly_tickets: {
    name: 'invoice hourly ticket |||| invoice hourly tickets',
    fieldNames: {
      ...pick(fieldNames, [
        'total_in_dollars',
        'ticket_number',
        'description',
      ]),
      regular_time_in_hours: 'RG hr',
      regular_rate_in_dollars: 'RG rate',
      overtime_in_hours: 'OT hr',
      overtime_rate_in_dollars: 'OT rate',
      doubletime_in_hours: 'DT hr',
      doubletime_rate_in_dollars: 'DT rate',
      worked_at_date: 'date',
      fees_in_dollars: 'Disposal charges',
    },
  },
  tonnage_ticket_invoices: {
    name: 'tonnage invoice |||| tonnage invoices',
    fieldNames: {
      ...pick(fieldNames, [
        'customer',
        'is_archived',
      ]),

      tonnage_rate: 'rate',
      period_end_date: 'date',
      invoice_number: 'invoice #',
      tickets_count: 'tickets',
      units_count: 'tons',
      sales_tax_in_dollars: 'tax',
      // total_in_dollars: 'subtotal',
      total_with_sales_tax_in_dollars: 'total',
    },
    labels: {
      invoice: {
        workType: 'Tonnage work',
        // total_in_dollars: 'Total',
        sales_tax_in_dollars: 'Tax',
        total_with_sales_tax_in_dollars: 'Total',
        tickets_count: 'Total tickets',
        units_count: 'Total tons',
      },
    },
    archiveAll: {
      action: 'archive all',
      confirmContent: 'Archive all tonnage ticket invoices?',
      confirmTitle: 'Archive tonnage ticket invoices',
      success: 'Successfully archived tonnage ticket invoices',
      error: 'Error archiving tonnage ticket invoices',
    },
  },
  invoice_tonnage_tickets: {
    name: 'invoice tonnage ticket |||| invoice tonnage tickets',
    fieldNames: {
      ...pick(fieldNames, [
        'worked_at_date',
        'net_weight_in_tons',
      ]),
      ticket_number: 'ticket',
      material: 'material',
      dropoff_site: 'dropoff',
      base_rate_in_dollars: 'haul rate',
      tickets_count: 'tickets',
      fuel_surcharge_in_dollars: 'fuel srch',
      material_surcharge_in_dollars: 'mtrl srch',
      equipment_rate_in_dollars: 'install rate',
      material_sale_price_in_dollars: 'sale price',
      sales_tax_in_dollars: 'tax',
      // total_in_dollars: 'total',
      total_with_sales_tax_in_dollars: 'total',
    },
    lines: {
      flat_rate: 'F',
    },
  },
  transfers: {
    preview: {
      name: 'transfer',
      isTransferring: 'Transferring...',
      action: 'transfer',
      confirmContent: 'Transfer all tickets and timesheets?\nThis cannot be undone.',
      confirmTitle: 'Transfer week ending %{date}',
      success: 'Successfully transferred tickets',
      error: 'Error transferring tickets',
      downloadError: 'Error downloading exports',
    },
  },
  employee_driver_pay_rollups: {
    name: 'driver payroll |||| driver payrolls',
    fieldNames: {
      ...pick(fieldNames, [
        'total_in_dollars',
        'is_archived',
        'driver',
        'period_end_date',
      ]),
      tickets_total_in_dollars: 'tickets total',
      total_line_item_quantity: 'tickets',
      minimum_hourly_wage_adjustment_in_dollars: 'min wage adj',
      tickets_regular_total_in_dollars: 'tickets reg pay',
      tickets_overtime_total_in_dollars: 'tickets OT pay',
      tickets_doubletime_total_in_dollars: 'tickets DT pay',
    },
    labels: {
      payroll: {
        driverType: 'Employee'
      },
    },
  },
  broker_driver_pay_rollups: {
    name: 'broker payroll |||| broker payrolls',
    fieldNames: {
      ...pick(fieldNames, [
        'total_in_dollars',
        'is_archived',
        'driver',
        'period_end_date',
      ]),
      total_line_item_quantity: 'tickets',
      regular_total_in_dollars: 'reg pay',
      overtime_total_in_dollars: 'OT pay',
      doubletime_total_in_dollars: 'DT pay',
    },
    labels: {
      payroll: {
        driverType: 'Broker'
      },
    },
  },
  driver_pay_rollup_tickets: {
    name: 'hourly ticket |||| hourly tickets',
    fieldNames: {
      ...pick(fieldNames, [
        'date',
        'ticket_number',
        'net_weight_in_tons',
      ]),
      date: 'date',
      base_rate_in_dollars: 'ton rate',
      fuel_surcharge_in_dollars: 'fuel',
      material_surcharge_in_dollars: 'mtrl',
      total_pay_in_dollars: 'total',
      driver_percent_of_base_rate: '% rate',
      regular_time_in_hours: 'RG hr',
      overtime_in_hours: 'OT hr',
      doubletime_in_hours: 'DT hr',
      regular_pay_rate_in_dollars: 'RG rate',
      overtime_pay_rate_in_dollars: 'OT rate',
      doubletime_pay_rate_in_dollars: 'DT rate',
      regular_travel_time_pay_rate_in_hours: 'RG Trvl rate',
      overtime_travel_time_pay_rate_in_hours: 'OT Trvl rate',
      doubletime_travel_time_pay_rate_in_hours: 'DT Trvl rate',
      regular_travel_time_in_hours: 'RG Trvl hr',
      overtime_travel_time_in_hours: 'OT Trvl hr',
      doubletime_travel_time_in_hours: 'DT Trvl hr',
      equipment_rate_in_dollars: 'eqpt',
    },
    lines: {
      flat_rate: 'F',
    }
  },
  employee_driver_aggregate_pay_rollups: {
    name: 'driver pay report |||| driver pay reports',
    fieldNames: {
      ...pick(fieldNames, [
        'first_name',
        'last_name',
      ]),
      sum_total_line_item_quantity: 'tickets',
      sum_tickets_regular_total_in_dollars: 'tickets reg pay',
      sum_tickets_overtime_total_in_dollars: 'tickets OT pay',
      sum_tickets_doubletime_total_in_dollars: 'tickets DT pay',
      sum_tickets_total_in_dollars: 'tickets total pay',
      sum_total_in_dollars: 'total pay',
      sum_minimum_hourly_wage_adjustment_in_dollars: 'min wage adjustment',
      ticket_date: 'ticket date'
    },
  },
  broker_driver_aggregate_pay_rollups: {
    name: 'broker pay report |||| broker pay reports',
    fieldNames: {
      ...pick(fieldNames, [
        'name',
      ]),
      sum_total_line_item_quantity: 'tickets',
      sum_regular_total_in_dollars: 'reg pay',
      sum_overtime_total_in_dollars: 'OT pay',
      sum_doubletime_total_in_dollars: 'DT pay',
      sum_total_in_dollars: 'total pay',
      ticket_date: 'ticket date',
    },
  },
  broker_driver_profits: {
    name: 'broker profit |||| broker profit',
    fieldNames: {
      ...pick(fieldNames, [
        'driver',
      ]),
      total_in_dollars: 'total pay',
      regular_total_in_dollars: 'reg pay',
      overtime_total_in_dollars: 'OT pay',
      doubletime_total_in_dollars: 'DT pay',
      hourly_income_total_in_dollars: 'hourly income',
      tonnage_income_total_in_dollars: 'tonnage income',
      income_total_in_dollars: 'total income',
      profit_total_in_dollars: 'total profit',
    },
  },
  broker_driver_aggregate_profits: {
    name: 'broker profit report |||| broker profit reports',
    fieldNames: {
      ...pick(fieldNames, [
        'name',
      ]),
      sum_total_line_item_quantity: 'tickets',
      sum_regular_total_in_dollars: 'reg pay',
      sum_overtime_total_in_dollars: 'OT pay',
      sum_doubletime_total_in_dollars: 'DT pay',
      sum_total_in_dollars: 'total pay',
      ticket_date: 'ticket date',
      sum_income_total_in_dollars: 'total income',
      sum_hourly_income_total_in_dollars: 'hourly income',
      sum_tonnage_income_total_in_dollars: 'tonnage income',
      sum_profit_total_in_dollars: 'total profit',
    },
  },
  certified_payroll_reports: {
    name: 'certified payroll report |||| certified payroll reports',
    fieldNames: {
      customer_name: 'contractor',
      certified_location: 'location',
      driver_pay_rollups_count: 'drivers',
    },
  },
  periods: {
    name: 'period |||| periods',
    fieldNames: {
      end_date: 'week ending',
    },
  },
  timesheets: {
    name: 'timesheet |||| timesheets',
    fieldNames: {
      ...pick(fieldNames, [
        'driver',
        'is_archived',
        'period',
        'period_end_date',
      ]),
      total_time_in_hours: 'total time',
      num_days_filled: 'num days filled',
      minimum_hourly_wage_in_dollars: 'min wage',
      is_holiday: 'holiday?',
    },
  },
  tenants: {
    name: 'business',
    fieldNames: {
      ...pick(fieldNames, [
        'address',
        'name',
      ]),
      minimum_hourly_wage_in_dollars: 'min hourly wage',
      sales_tax_rate: 'sales tax rate',
    },
    form: {
      salesTaxRateHelperText: 'Enter as a decimal (e.g. 0.0725 for 7.25%)',
    },
  },
  users: {
    name: 'account',
    fieldNames: {
      email: 'email',
      new_password: 'new password',
      old_password: 'old password',
    },
  },
  exports: {
    name: 'export configuration |||| export configurations',
    export_type: {
      invoice: 'invoice',
      broker_driver_pay_rollup: 'broker payroll',
      revenue: 'revenue',
    },
    fieldNames: {
      ...pick(fieldNames, [
        'name',
      ]),
      export_type: 'export type',
      include_csv_headers: 'include csv headers?',
    },
    form: {
      general: {
        typeDisabled: 'Remove all export fields to change export type',
      },
    },
  },
  export_fields: {
    fieldNames: {
      ...pick(fieldNames, [
        'name',
      ]),
      dynamic_value_type: 'dynamic value type',
      static_value: 'static value',
      is_negative: 'negative?',
    },
    dynamic_value_type: {
      _static: '-- leave blank for static value --',
      broker_driver_pay_rollup: {
        driver_ledger_code: 'driver ledger code',
        pay_rollup_date: 'payroll date',
        pay_rollup_total: 'payroll total',
      },
      invoice: {
        customer_ledger_code: 'customer ledger code',
        invoice_number: 'invoice number',
        invoice_date: 'invoice date',
        invoice_total: 'invoice total',
      },
      revenue: {
        ticket_type: 'ticket type',
        ticket_date: 'ticket date',
        ticket_number: 'ticket number',
        customer_name: 'customer name',
        item_name: 'item name',
        dropoff_site_name: 'dropoff site name',
        job_name: 'job name',
        quantity: 'quantity',
        rate: 'rate',
        is_flat_rate: 'flate rate?',
      },
    }
  },
  work_classes: {
    name: 'work class |||| work classes',
    fieldNames: {
      ...pick(fieldNames, [
        'name',
      ]),
    },
  },
  tonnage_revenues: {
    name: 'tonnage revenue |||| tonnage revenues',
    fieldNames: {
      ...aggregateRevenues,
      units_count: 'tons',
    }
  },
  hourly_revenues: {
    name: 'hourly revenue |||| hourly revenues',
    fieldNames: {
      ...aggregateRevenues,
      units_count: 'hours',
    }
  },
  tonnage_ticket_uploads: {
    name: 'ticket image upload |||| ticket image uploads',
    fieldNames: {
      created_at: 'uploaded on',
      ticket_image_url: 'file',
      ticket_image_filename: 'filename',
      sender: 'Sender',
      tonnage_ticket_pdf_upload: 'PDF upload',
      imageUpload: 'image upload',
    },
    list: {
      download: 'Download',
      downloadError: 'Error downloading tonnage ticket uploads',
    },
    form: {
      actions: {
        create: 'upload',
      },
    },
  },
  tonnage_ticket_audits: {
    name: 'Tonnage ticket Audit |||| Tonnage ticket Audits',
    fieldNames: {
      created_at: 'uploaded on',
      pdf_file_url: 'file',
      spreadsheet_filename: 'filename',
      ticket_vendor: 'ticket vendor',
      fileUpload: 'file upload',
      page_count: 'pages total',
      tonnage_ticket_uploads_count: 'pages processed',
      rerun: 'rerun'
    },
    form: {
      actions: {
        create: 'upload',
      },
    },
  },
  tonnage_ticket_spreadsheet_uploads: {
    name: 'ticket spreadsheet upload |||| ticket spreadsheet uploads',
    fieldNames: {
      created_at: 'uploaded on',
      spreadsheet_file_url: 'file',
      spreadsheet_filename: 'filename',
      fileUpload: 'file upload',
      ticket_vendor: 'ticket vendor',
    },
    form: {
      actions: {
        create: 'upload',
      },
    },
  },
  tonnage_ticket_pdf_uploads: {
    name: 'ticket PDF upload |||| ticket PDF uploads',
    fieldNames: {
      created_at: 'uploaded on',
      pdf_file_url: 'file',
      pdf_filename: 'filename',
      fileUpload: 'file upload',
      page_count: 'pages total',
      tonnage_ticket_uploads_count: 'pages processed'
    },
    form: {
      actions: {
        create: 'upload',
      },
    },
  },
  tonnage_ticket_extractions: {
    name: 'ticket extraction |||| ticket extractions',
    fieldNames: {
      ...pick(fieldNames, [
        'ticket_number',
        'customer',
        'recipient',
        'material',
        'dropoff_site',
        'pickup_site',
        'net_weight_in_tons',
      ]),
      created_at: 'uploaded',
      weighed_at: 'date',
      truck_number: 'truck',
      has_error: 'error?',
    },
  },
  pending_tonnage_tickets: {
    name: 'pending tonnage ticket |||| pending tonnage tickets',
    fieldNames: tonnageTicketsFieldNames,
    form: {
      ...tonnageTicketsForm,
      edit: {
        ...tonnageTicketsForm.edit,
        extractedText: 'Extracted text: \'%{value}\'',
        saveButtonLabel: 'complete',
        saveAndSplitLabel: 'complete & split',
      },
      notifications: {
        saveAndSplitSuccess: `Split tonnage ticket created`,
      },
    },
    list: {
      completeAll: {
        buttonLabel: 'Complete all',
        confirmTitle: 'Complete %{smart_count} tonnage ticket |||| Complete %{smart_count} tonnage tickets',
        confirmContent: 'Complete all eligible tonnage tickets?\nUnsuccessful completions will remain in pending list for manual review or deletion.',
        isCompleting: 'Completing...',
        notifyFailure: 'Error completing tickets',
        notifySuccess: 'Completed %{smart_count} tonnage ticket |||| Completed %{smart_count} tonnage tickets',
      },
    },
  },
  pickup_sites: {
    name: 'pickup site |||| pickup sites',
  },
  dropoff_sites: {
    name: 'dropoff site |||| dropoff sites',
  },
  customers: {
    name: 'customer |||| customers',
  },
  recipients: {
    name: 'recipient |||| recipients',
  },
  pending_resources: {
    preview: {
      name: 'pending resources'
    },
    form: {
      actions: {
        createNew: 'create',
        mapExisting: 'link',
      },
      toggle: 'create new?'
    },
  },
  pending_material_extraction_mappings: {
    name: 'material extraction |||| material extractions',
    fieldNames: {
      ...extractionMappings,
      ...materialsFieldNames,
      resource_id: 'material',
    },
  },
  pending_customer_extraction_mappings: {
    name: 'customer extraction |||| customer extractions',
    fieldNames: {
      ...extractionMappings,
      ...companiesFieldNames,
      resource_id: 'company',
    },
  },
  pending_recipient_extraction_mappings: {
    name: 'recipient extraction |||| recipient extractions',
    fieldNames: {
      ...extractionMappings,
      ...companiesFieldNames,
      resource_id: 'company',
    },
  },
  pending_pickup_site_extraction_mappings: {
    name: 'pickup site extraction |||| pickup site extractions',
    fieldNames: {
      ...extractionMappings,
      ...sitesFieldNames,
      resource_id: 'site',
    },
  },
  pending_dropoff_site_extraction_mappings: {
    name: 'dropoff site extraction |||| dropoff site extractions',
    fieldNames: {
      ...extractionMappings,
      ...sitesFieldNames,
      resource_id: 'site',
    },
  },
  pending_truck_extraction_mappings: {
    name: 'trucks extraction |||| trucks extractions',
    fieldNames: {
      ...extractionMappings,
      ...trucksFieldNames,
      resource_id: 'truck',
    },
  },
  material_extraction_mappings: {
    name: 'material mapping |||| material mappings',
  },
  company_extraction_mappings: {
    name: 'company mapping |||| company mappings',
  },
  site_extraction_mappings: {
    name: 'site mapping |||| site mappings',
  },
  truck_extraction_mappings: {
    name: 'truck mapping |||| truck mappings',
  },
  extraction_mappings: {
    name: 'extraction mapping |||| extraction mappings',
    fieldNames: extractionMappings,
  },
  pending_tonnage_rates: {
    name: 'tonnage rate |||| tonnage rates',
    fieldNames: tonnageRatesFieldNames,
  },
  sms_numbers: {
    name: 'SMS number |||| SMS numbers',
  },
  material_sale_prices: {
    name: 'material sale price |||| material sale prices',
    fieldNames: {
      ...pick(fieldNames, [
        'material',
        'pickup_site',
        'name',
      ]),
      price_in_dollars: 'price',
    },
  },
  zones: {
    name: 'zone |||| zones',
    fieldNames: {
      ...pick(fieldNames, [
        'name',
      ]),
    },
  },
  zone_rates: {
    name: 'zone rate |||| zone rates',
    fieldNames: {
      zone_1: 'zone 1',
      zone_2: 'zone 2',
      rate_in_dollars: 'rate',
    },
  },
}

export default {
  resources,
}
