import { useCallback } from 'react'
import { useEarliestIncompletePeriod } from '../../hooks'
import { DateInput } from '../custom'
import { dateOnlyStringToJSDate } from '../../util/date'

const PeriodDatePicker = props => {
  const period = useEarliestIncompletePeriod()

  const dayOfWeek = period ? dateOnlyStringToJSDate(period.end_date).getDay() : null

  const shouldDisableDate = useCallback(date => {
    return date.getDay() !== dayOfWeek
  }, [dayOfWeek])

  return (
    <DateInput
      disabled={!period}
      initialFocusedDate={period?.end_date}
      shouldDisableDate={shouldDisableDate}
      {...props}
    />
  )
}

export default PeriodDatePicker
