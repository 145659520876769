import {
  useState,
  useCallback,
  useEffect,
  // useMemo,
} from 'react'
import {
  ReferenceInput,
  TextInput,
  FormDataConsumer,
  useDataProvider,
} from 'react-admin'
import get from 'lodash/get'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { Autocomplete, DateInput, NumberInput, BooleanInput } from '../../custom'
import { useForm } from 'react-final-form'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import {
  useLabel,
  useTranslateResource,
} from '../../../hooks'
import {
  useTonnageRateFields,
  useTonnageRateReferenceFields,
  useAutofillFields,
  // useReferenceLookup,
} from '../../form/functions'
import { relationships } from '../../../data-model'
import { Form, getValidator } from '../../form'
import AdjustNumeric from './adjust-numeric'

const useStyles = makeStyles(theme => ({
  rateFieldInputCol: {
    flexGrow: 1,
  },
  rateFieldAdjustControlCol: {
    marginTop: theme.spacing(2),
  },
}))

const materialSalePriceLookupSources = [
  'material',
  'pickup_site',
]

const materialSalePriceReferenceFields = materialSalePriceLookupSources.map(source => ({ source }))

const autofillMaterialSalePriceFields = [
  'material_sale_price_in_dollars',
]

// const autofillZoneRateFields = [
//   'base_rate_in_dollars',
// ]

const FormBody = props => {
  const { resource } = props
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(props)
  const rateFields = useTonnageRateFields(false)
  const numberRateFields = rateFields.filter(({ type }) => type === 'number')
  const label = useLabel(props)
  const classes = useStyles()
  const referenceFields = useTonnageRateReferenceFields()
  const form = useForm()
  const { change: changeFormField, resetFieldState } = form
  const dataProvider = useDataProvider()
  const { record } = props
  const isNewRecord = !record.id
  const isClonedRecord = Object.keys(record).length > 0 && isNewRecord
  const translate = useTranslateResource(resource, 'form')

  const [salePriceAutofillSource, setSalePriceAutofillSource] = useState(null)

  // const {
  //   selectedRecord: selectedPickupSite,
  //   onSelectedIdChange: onSelectedPickupSiteIdChange
  // } = useReferenceLookup(resourceRelationships['pickup_site'], record.pickup_site)

  // const {
  //   selectedRecord: selectedDropoffSite,
  //   onSelectedIdChange: onSelectedDropoffSiteIdChange
  // } = useReferenceLookup(resourceRelationships['dropoff_site'], record.dropoff_site)

  // const [zoneRateAutofillSource, setZoneRateAutofillSource] = useState(null)

  // const selectedZoneIds = useMemo(() => {
  //   return [selectedPickupSite?.zone?.id, selectedDropoffSite?.zone?.id].filter(Boolean)
  // }, [selectedPickupSite, selectedDropoffSite])

  const shouldAutofill = useCallback(() => {
    return isNewRecord && !isClonedRecord
  }, [isNewRecord, isClonedRecord])

  const [
    getMaterialSalePriceIsAutofilled,
    onAutofillMaterialSalePriceFieldChange,
   ]= useAutofillFields(autofillMaterialSalePriceFields, salePriceAutofillSource, shouldAutofill)

  // const [
  //   getZoneRateIsAutofilled,
  //   onAutofillZoneRateFieldChange,
  //  ]= useAutofillFields(autofillZoneRateFields, zoneRateAutofillSource, shouldAutofill)

  const onAcceptRateAdjustment = (attribute, value) => {
    changeFormField(attribute, value)
    resetFieldState(attribute)

    if (autofillMaterialSalePriceFields.includes(attribute)) {
      onAutofillMaterialSalePriceFieldChange(attribute)
    }

    // if (autofillZoneRateFields.includes(attribute)) {
    //   onAutofillZoneRateFieldChange(attribute)
    // }
  }

  const [materialSalePriceReferenceFieldValues, setMaterialSalePriceReferenceFieldValues] = useState(() => {
    return materialSalePriceReferenceFields.reduce((accum, { source }) => {
      return {
        ...accum,
        [source]: get(record, [source, 'id']),
      }
    }, {})
  })

  const onRateReferenceFieldChange = useCallback((source, value) => {
    if (materialSalePriceLookupSources.includes(source)) {
      setMaterialSalePriceReferenceFieldValues(prev => ({ ...prev, [source]: value }))
    }

    // if (source === 'pickup_site') {
    //   onSelectedPickupSiteIdChange(value)
    // }

    // if (source === 'dropoff_site') {
    //   onSelectedDropoffSiteIdChange(value)
    // }
  // }, [onSelectedDropoffSiteIdChange, onSelectedPickupSiteIdChange])
  }, [])

  // const getReferenceFieldHelperText = source => {
  //   switch (source) {
  //     case 'pickup_site':
  //       if (selectedPickupSite) {
  //         return selectedPickupSite.zone ? selectedPickupSite.zone.name : translate('general.noZone')
  //       }
  //       break
  //     case 'dropoff_site':
  //       if (selectedDropoffSite) {
  //         return selectedDropoffSite.zone ? selectedDropoffSite.zone.name : translate('general.noZone')
  //       }
  //       break
  //     default:
  //       return null
  //   }
  // }

  const getRateFieldHelperText = source => {
    let translatePath, autofilled, autofillSource

    switch (source) {
      case 'material_sale_price_in_dollars':
        translatePath = 'materialSalePrice'
        autofillSource = salePriceAutofillSource
        autofilled = getMaterialSalePriceIsAutofilled(source)
        break
      // case 'base_rate_in_dollars':
      //   translatePath = 'zoneRate'
      //   autofillSource = zoneRateAutofillSource
      //   autofilled = getZoneRateIsAutofilled(source)
      //   break
      default:
        return null
    }

    if (autofillSource) {
      const value = get(autofillSource, source)
      if (autofilled) {
        return translate(`general.${translatePath}.autofilledValueFromRate`, { value })
      } else {
        return translate(`general.${translatePath}.currentRateValue`, { value })
      }
    } else {
      return translate(`general.${translatePath}.noRate`)
    }
  }

  const onRateFieldChange = source => {
    switch (source) {
      case 'material_sale_price_in_dollars':
        onAutofillMaterialSalePriceFieldChange(source)
        break
      // case 'base_rate_in_dollars':
      //   onAutofillZoneRateFieldChange(source)
      //   break
      default:
        break
    }
  }

  useEffect(() => {
    if (Object.values(materialSalePriceReferenceFieldValues).some(v => !v)) {
      setSalePriceAutofillSource(null)
    } else {
      (async () => {
        const filter = {
          ...Object.entries(materialSalePriceReferenceFieldValues).reduce((accum, [field, value]) => {
            return {
              ...accum,
              [`${field}_id_eq`]: value,
            }
          }, {}),
        }
        const responseData = await dataProvider.getList('material_sale_prices', { filter })
        const lookupValue = get(responseData, 'data[0].price_in_dollars')
        if (lookupValue) {
          setSalePriceAutofillSource({ material_sale_price_in_dollars: lookupValue } )
        } else {
          setSalePriceAutofillSource(null)
        }
      })()
    }
  }, [materialSalePriceReferenceFieldValues, dataProvider])

  // useEffect(() => {
  //   if (selectedZoneIds.length !== 2) {
  //     setZoneRateAutofillSource(null)
  //   } else {
  //     (async () => {
  //       // bidirectional
  //       const filter = {
  //         zone_1_id_in: selectedZoneIds,
  //         zone_2_id_in: selectedZoneIds,
  //       }
  //       const responseData = await dataProvider.getList('zone_rates', { filter })
  //       const lookupValue = get(responseData, 'data[0].rate_in_dollars')
  //       if (lookupValue) {
  //         setZoneRateAutofillSource({ base_rate_in_dollars: lookupValue } )
  //       } else {
  //         setZoneRateAutofillSource(null)
  //       }
  //     })()
  //   }
  // }, [selectedZoneIds, dataProvider])

  return (
    <Box className={formClasses.grid}>
      <Box>
        {
          referenceFields.map(({ source, required, label: _label}) => (
            <ReferenceInput
              {...referenceInputOptions(resourceRelationships[source])}
              {...formInputOptions}
              key={source}
              source={`${source}.id`}
              reference={resourceRelationships[source]}
              label={label(_label)}
              required={required}
              onChange={onRateReferenceFieldChange.bind(null, source)}
            >
              <Autocomplete
                validate={getValidator(required)}
                // helperText={getReferenceFieldHelperText(source)}
              />
            </ReferenceInput>
          ))
        }
      </Box>

      <Box>
        {
          numberRateFields.map(({ source, required: _required, label: _label }) => (
            <FormDataConsumer key={source}>
              {({ formData }) => {
                const { [source]: value } = formData
                return (
                  <Grid container key={source} spacing={1}>
                    <Grid item className={classes.rateFieldInputCol}>
                      <NumberInput
                        {...formInputOptions}
                        source={source}
                        label={label(_label)}
                        step={0.01}
                        min={0}
                        required={_required}
                        onChange={onRateFieldChange.bind(null, source)}
                        helperText={getRateFieldHelperText(source)}
                      />
                    </Grid>
                    <Grid item className={classes.rateFieldAdjustControlCol}>
                      <AdjustNumeric
                        value={value}
                        attribute={source}
                        resource={resource}
                        disabled={!value}
                        onAccept={onAcceptRateAdjustment.bind(null, source)}
                      />
                    </Grid>
                  </Grid>
                )
              }}
            </FormDataConsumer>
          ))
        }
      </Box>

      <Box>
        <TextInput
          {...formInputOptions}
          source='code'
          label={label('code')}
          validate={getValidator(false)}
        />

        <TextInput
          {...formInputOptions}
          source='description'
          label={label('description')}
          validate={getValidator(false)}
        />
        <DateInput
          {...formInputOptions}
          source='start_date'
          label={label('start_date')}
          validate={getValidator(false)}
        />

        <DateInput
          {...formInputOptions}
          source='end_date'
          label={label('end_date')}
          validate={getValidator(false)}
        />

        <BooleanInput
          source='is_flat_rate'
          label={label('is_flat_rate')}
          defaultValue={false}
          tabIndex={-1}
        />

        <BooleanInput
          source='is_active'
          label={label('is_active')}
          defaultValue={true}
          tabIndex={-1}
        />
      </Box>
    </Box>
  )
}

const TonnageRatesForm = (props) => {
  const { record } = props
  const clonedRecord = (() => {
    if (!record.id) return
    const { id: _id, ...clonedRecordProps } = record
    return {
      ...clonedRecordProps,
      is_active: false,
    }
  })()

  return (
    <Form
      {...props}
      clonedRecord={clonedRecord}
    >
      <FormBody {...props} />
    </Form>
  )
}

export default TonnageRatesForm
