import { useMemo, useState } from 'react'
import {
  Edit,
  TabbedShowLayout,
  Tab,
  useNotify,
} from 'react-admin'
import TonnageForm from '../tonnage-tickets/form'
import HourlyForm from '../hourly-tickets/form'
import { editOptions } from '../../util/component-options'
import { useTranslateResource } from '../../../hooks'
import { withTicketImage } from '../../hoc'
import { withStyles } from '@material-ui/core/styles'
import SplitIcon from '@material-ui/icons/CallSplit'
import { useCallback } from 'react'

const StyledTabbedShowLayout = withStyles(() => {
  return {
    content: {
      padding: 0,
    },
  }
})(TabbedShowLayout)

const TonnageFormWithTicketImage = withTicketImage(TonnageForm)
const HourlyFormWithTicketImage = withTicketImage(HourlyForm)

const Hourly = (props) => {
  const resource = 'pending_hourly_tickets'
  const translate = useTranslateResource(resource, 'form')

  return (
    <HourlyFormWithTicketImage
      {...props}
      resource={resource}
      saveButtonLabel={translate('edit.saveButtonLabel')}
      basePath='/pending_tonnage_tickets'
      useOptimisticSave={false}
    />
  )
}

const Tonnage = (props) => {
  const { record: propsRecord } = props
  const resource = 'pending_tonnage_tickets'
  const basePath = `/${resource}`
  const translate = useTranslateResource(resource, 'form')
  const notify = useNotify()
  const [splitRecord, setSplitRecord] = useState(null)

  const record = useMemo(() => {
    return splitRecord || propsRecord
  }, [splitRecord, propsRecord])

  const onAltSubmitSuccess = useCallback((data) => {
    setSplitRecord(data)
    notify('ra.notification.message', 'info', { message: translate('notifications.saveAndSplitSuccess') })
  }, [notify, translate])

  return (
    <TonnageFormWithTicketImage
      {...props}
      record={record}
      resource='pending_tonnage_tickets'
      saveButtonLabel={translate('edit.saveButtonLabel')}
      basePath={basePath}
      useOptimisticSave={false}
      altSubmitProps={{
        label: translate('edit.saveAndSplitLabel'),
        icon: <SplitIcon />,
        onSuccess: onAltSubmitSuccess,
        params: {
          is_split: true,
        },
      }}
    />
  )
}

const EditView = (props) => {
  const { record } = props
  const imageUrl = record.tonnage_ticket_extraction?.ticket_image_url
  const senderPhone = record.tonnage_ticket_extraction?.sender?.phone

  return (
    <StyledTabbedShowLayout record={record}>
      <Tab
        label='tonnage'
        path='tonnage'
      >
        <Tonnage
          imageUrl={imageUrl}
          senderPhone={senderPhone}
        />
      </Tab>
      <Tab
        label='hourly'
        path='hourly'
        resource='pending_hourly_tickets'
      >
        <Hourly
          imageUrl={imageUrl}
          senderPhone={senderPhone}
        />
      </Tab>
    </StyledTabbedShowLayout>
  )
}

const PendingTonnageTicketsEdit = props => {
  return (
    <Edit {...editOptions} {...props}>
      <EditView />
    </Edit>
  )
}

export default PendingTonnageTicketsEdit
