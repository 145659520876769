import mandatedWageCoefficientsComponents from './mandated-wage-coefficients'
import tonnageTicketsComponents from './tonnage-tickets'
import tonnageRatesComponents from './tonnage-rates'
import materialsComponents from './materials'
import sitesComponents from './sites'
import companiesComponents from './companies'
import employeeDriversComponents from './employee-drivers'
import brokerDriversComponents from './broker-drivers'
import trucksComponents from './trucks'
import payscalesComponents from './payscales'
import hourlyRatesComponents from './hourly-rates'
import hourlyTicketsComponents from './hourly-tickets'
import hourlyTicketInvoicesComponents from './hourly-ticket-invoices'
import tonnageTicketInvoicesComponents from './tonnage-ticket-invoices'
import employeeDriverPayRollupsComponents from './employee-driver-pay-rollups'
import brokerDriverPayRollupsComponents from './broker-driver-pay-rollups'
import employeeDriverAggregatePayRollupsComponents from './employee-driver-aggregate-pay-rollups'
import brokerDriverAggregatePayRollupsComponents from './broker-driver-aggregate-pay-rollups'
import brokerDriverAggregateProfitsComponents from './broker-driver-aggregate-profits'
import timesheetsComponents from './timesheets'
import exportsComponents from './exports'
import workClassesComponents from './work-classes'
import tonnageRevenuesComponents from './tonnage-revenues'
import hourlyRevenuesComponents from './hourly-revenues'
import tonnageTicketUploadsComponents from './tonnage-ticket-uploads'
// import tonnageTicketSpreadsheetUploadsComponents from './tonnage-ticket-spreadsheet-uploads'
import tonnageTicketAuditsComponents from './tonnage-ticket-audits'
import tonnageTicketPdfUploadsComponents from './tonnage-ticket-pdf-uploads'
import tonnageTicketExtractionsComponents from './tonnage-ticket-extractions'
import pendingTonnageTicketsComponents from './pending-tonnage-tickets'
import materialSalePricesComponents from './material-sale-prices'

// Comment out - this feature as implemented is not useful. Consider reworking.
// import zonesComponents from './zones'
// import zoneRatesComponents from './zone-rates'

import { capitalize } from '../../util'
import camelCase from 'camelcase'
import { resourceTypes } from '../../data-model'

const componentsMap = {
  mandatedWageCoefficientsComponents,
  tonnageTicketsComponents,
  tonnageRatesComponents,
  materialsComponents,
  sitesComponents,
  companiesComponents,
  employeeDriversComponents,
  brokerDriversComponents,
  trucksComponents,
  payscalesComponents,
  hourlyRatesComponents,
  hourlyTicketsComponents,
  hourlyTicketInvoicesComponents,
  tonnageTicketInvoicesComponents,
  employeeDriverPayRollupsComponents,
  brokerDriverPayRollupsComponents,
  employeeDriverAggregatePayRollupsComponents,
  brokerDriverAggregatePayRollupsComponents,
  brokerDriverAggregateProfitsComponents,
  timesheetsComponents,
  exportsComponents,
  workClassesComponents,
  tonnageRevenuesComponents,
  hourlyRevenuesComponents,
  tonnageTicketUploadsComponents,
  // tonnageTicketSpreadsheetUploadsComponents,
  tonnageTicketPdfUploadsComponents,
  tonnageTicketExtractionsComponents,
  pendingTonnageTicketsComponents,
  tonnageTicketAuditsComponents,
  materialSalePricesComponents,
  // zonesComponents,
  // zoneRatesComponents,
}

const views = [
  'list',
  'create',
  'edit',
]

const mapComponents = module => {
  return views.reduce((accum, view) => {
    return {
      ...accum,
      [view]: module[capitalize(view)],
      options: {
        isMenuItem: true,
      },
    }
  }, {})
}

const adminResources = resourceTypes.map(name => {
  const module = componentsMap[`${camelCase(name)}Components`]

  return {
    name,
    ...(module ? mapComponents(module) : null),
  }
})

export default adminResources
